import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { intervalToDuration } from 'date-fns';
import ReactCountryFlag from 'react-country-flag';
import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

const humanizeDuration = (seconds) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  const parts = [];
  if (duration.days) parts.push(`${duration.days} день`);
  if (duration.hours) parts.push(`${duration.hours} час`);
  if (duration.minutes) parts.push(`${duration.minutes} мин.`);
  if (duration.seconds) parts.push(`${duration.seconds} сек.`);

  return parts.join(', ');
};

const getSteamData = async (steamID) => {
  try {
    const response = await axios.post(
      'https://steamdata.fullbread.ru/steam-users',
      { steamIds: [steamID.split(':')[1]] }
    );

    const playerData = response.data[0];
    return playerData;
  } catch (error) {
    console.error('Error fetching Steam data:', error);
    return null;
  }
};

export default function Example() {
  const [endpoint, setEndpoint] = useState('public');
  const [playersData, setPlayersData] = useState([]);
  const [queryPlayersData, setQueryPlayersData] = useState([]);
  const [avatars, setAvatars] = useState({});

  useEffect(() => {
    axios
      .get(`https://request.fullbread.ru/rcon/players/${endpoint}`)
      .then((response) => {
        const players = response.data.players.filter((player) => player.CountryCode);
        setPlayersData(players);
        
        players.forEach(async (player) => {
          const steamID = player.NetID;
          if (steamID.startsWith('SteamNWI:')) {
            const steamData = await getSteamData(steamID);
            if (steamData) {
              setAvatars((prevAvatars) => ({
                ...prevAvatars,
                [steamID]: {
                  avatarUrl: steamData.avatarmedium,
                  profileUrl: steamData.profileurl,
                },
              }));
            }
          }
        });
      })
      .catch((error) => {
        console.error('Error fetching players data:', error);
      });
  }, [endpoint]);

  useEffect(() => {
    axios
      .get(`https://request.fullbread.ru/players/${endpoint}`)
      .then((response) => {
        setQueryPlayersData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching query players data:', error);
      });
  }, [endpoint]);

  useEffect(() => {
    if (playersData.length && queryPlayersData.length) {
      const updatedPlayersData = playersData.map((player) => {
        const matchingPlayer = queryPlayersData.find((qp) => qp.name === player.Name);
        if (matchingPlayer) {
          player.Duration = matchingPlayer.duration;
        }
        return player;
      });
      setPlayersData(updatedPlayersData);
    }
  }, [playersData, queryPlayersData]);

  const handleEndpointChange = useCallback((newEndpoint) => {
    setPlayersData([]);
    setQueryPlayersData([]);
    setEndpoint(newEndpoint);
  }, []);

  return (
    <div className="bg-darker-light min-h-screen px-5">
      <div className="py-10 container mx-auto">
        <div className="flex gap-4 mb-6">
          <button
            className={`px-4 py-2 border rounded-lg p-2 ${endpoint === 'public' ? 'bg-darker-light text-white' : 'bg-gray-200'}`}
            onClick={() => handleEndpointChange('public')}
          >
            classic
          </button>
          <button
            className={`px-4 py-2 border rounded-lg p-2 ${endpoint === 'modded' ? 'bg-darker-light text-white' : 'bg-gray-200'}`}
            onClick={() => handleEndpointChange('modded')}
          >
            hardcore
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="mt-6 w-full whitespace-nowrap text-left">
            <colgroup>
              <col className="w-full sm:w-4/12" />
              <col className="lg:w-4/12" />
              <col className="lg:w-2/12" />
              <col className="lg:w-1/12" />
            </colgroup>
            <thead className="border-b border-white/10 text-sm leading-6 text-white">
              <tr>
                <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                  Игрок
                </th>
                <th scope="col" className="py-2 pl-0 pr-8 font-semibold sm:table-cell">
                  Счет
                </th>
                <th scope="col" className="py-2 pl-0 pr-8 font-semibold sm:table-cell">
                  Страна
                </th>
                {/* <th scope="col" className="py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                  Сессия
                </th> */}
              </tr>
            </thead>
            <tbody className="divide-y divide-white/5">
              {playersData.map((player) => (
                <tr key={player.ID}>
                  <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                    <div className="flex items-center gap-x-4">
                      {avatars[player.NetID]?.avatarUrl ? (
                        <img
                          src={avatars[player.NetID].avatarUrl}
                          alt={`${player.Name} avatar`}
                          className="h-8 w-8 rounded-full bg-gray-800"
                        />
                      ) : (
                        <div className="bg-gray-300 w-8 h-8 rounded-full flex items-center justify-center"></div>
                      )}
                      <a
                        href={avatars[player.NetID]?.profileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="truncate text-sm font-medium leading-6 text-white"
                      >
                        {player.Name}
                      </a>
                    </div>
                  </td>
                  <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-20">
                    <div className="flex gap-x-3">
                      <div className="font-mono text-sm leading-6 text-gray-400">{player.Score}</div>
                    </div>
                  </td>
                  <td className="py-4 pl-0 pr-4 sm:table-cell sm:pr-8 lg:pr-20">
                    <ReactCountryFlag
                      countryCode={player.CountryCode}
                      title={countries.getName(player.CountryCode, 'ru')}
                      svg
                    />
                  </td>
                  {/* <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                    <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                      {humanizeDuration(player.Duration)}
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
