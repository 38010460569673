import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Heading from './components/header/with_centered_logo';
import FullWidthTable from './components/application-ui/lists/tables/full_width_with_avatars_on_dark';
import ServerInfo from './components/stats/server_info';
import ServerStats from './components/stats/server_stats'; 

const App = () => {
  return (
    <Router>
      <div>
        {/* <Heading /> */}
        <Routes>
          <Route path="/" element={
            <>
              <ServerInfo />
              <FullWidthTable />
            </>
          } />
          {/* <Route path="/stats" element={<ServerStats />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
