import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PocketBase from 'pocketbase';
import { CheckCircleIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';

const pb = new PocketBase('https://pocketbase.fullbread.ru/');

const Example = () => {
    const [publicServerData, setPublicServerData] = useState({
        name: 'Loading...',
        map: 'Loading...',
        players: 'Loading...',
        max_players: 'Loading...',
    });

    const [moddedServerData, setModdedServerData] = useState({
        name: 'Loading...',
        map: 'Loading...',
        players: 'Loading...',
        max_players: 'Loading...',
    });

    const [maps, setMaps] = useState([]);
    const [error, setError] = useState(null);
    const [isCopiedPublic, setIsCopiedPublic] = useState(false);
    const [isCopiedModded, setIsCopiedModded] = useState(false);
    const [ipPublic] = useState('193.164.16.143:27102');
    const [ipModded] = useState('193.164.16.143:27202');

    useEffect(() => {
        const fetchPublicServerData = async () => {
            try {
                const response = await axios.get('https://request.fullbread.ru/server-info/public', {
                    headers: {
                        'Cache-Control': 'no-cache'
                    }
                });
                const data = response.data;

                setPublicServerData({
                    name: data.name,
                    map: data.map,
                    players: data.players,
                    max_players: data.max_players,
                });
            } catch (err) {
                console.error('Error fetching public server data:', err);
                setPublicServerData({
                    name: 'Error loading data',
                    map: 'Error loading data',
                    players: 'Error loading data',
                    max_players: 'Error loading data',
                });
            }
        };

        const fetchModdedServerData = async () => {
            setTimeout(async () => {
                try {
                    const response = await axios.get('https://request.fullbread.ru/server-info/modded', {
                        headers: {
                            'Cache-Control': 'no-cache'
                        }
                    });
                    const data = response.data;

                    setModdedServerData({
                        name: data.name,
                        map: data.map,
                        players: data.players,
                        max_players: data.max_players,
                    });
                } catch (err) {
                    console.error('Error fetching modded server data:', err);
                    setModdedServerData({
                        name: 'Error loading data',
                        map: 'Error loading data',
                        players: 'Error loading data',
                        max_players: 'Error loading data',
                    });
                }
            }, 500);
        };

        const fetchMaps = async () => {
            try {
                const result = await pb.collection('get_maps').getList(1, 50);
                setMaps(result.items);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchPublicServerData();
        fetchModdedServerData();
        fetchMaps();
    }, []);

    const handleCopyToClipboard = async (ip) => {
        try {
            await navigator.clipboard.writeText(ip);
            setIsCopiedPublic(true);
            setTimeout(() => {
                setIsCopiedPublic(false);
            }, 2000);
        } catch (error) {
            console.error('Ошибка копирования в буфер обмена:', error);
        }
    };

    const handleCopyToClipboardModded = async (ip) => {
        try {
            await navigator.clipboard.writeText(ip);
            setIsCopiedModded(true);
            setTimeout(() => {
                setIsCopiedModded(false);
            }, 2000);
        } catch (error) {
            console.error('Ошибка копирования в буфер обмена:', error);
        }
    };

    const getMapImagePath = (map) => {
        if (!map) {
            return '';
        }

        const collectionId = map.collectionId;
        const recordId = map.id;
        const fileName = map.preview;

        return `https://pocketbase.fullbread.ru/api/files/${collectionId}/${recordId}/${fileName}`;
    };

    const findMapByCode = (mapCode) => {
        return maps.find((map) => map.code === mapCode);
    };

    return (
        <section className="body-font bg-darker-light">
            <h1 className="container mx-auto flex px-5 pt-16 md:flex-row flex-col items-center title-font sm:text-3xl text-3xl mb-0 font-medium text-white">Статус серверов</h1>

            {/* Блок с публичным сервером */}
            <div className="container mx-auto flex px-5 pt-16 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center md:w-full">
                    <p className="font-semibold   mt-2 text-white w-full">Название: <span className="font-light">{publicServerData.name}</span> </p>
                    <p className="font-semibold   mt-2 text-white w-full">Карта: <span className="font-light">{findMapByCode(publicServerData.map)?.name ?? publicServerData.map}</span></p>
                    <p className="font-semibold   mt-2 text-white w-full mb-8">Режим: <span className="font-light">{findMapByCode(publicServerData.map)?.mode ?? ''}</span></p>




                    {/* Кнопка копирования IP */}
                    <div className="flex flex-col sm:flex-row items-center mb-4">
                        <input
                            type="text"
                            readOnly
                            className="border rounded-lg p-2 bg-white text-gray-700 w-full sm:w-auto text-center mb-2 sm:mb-0"
                            value={ipPublic}
                        />
                        <button
                            className="bg-gray-100 inline-flex py-2 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none sm:ml-2"
                            onClick={() => handleCopyToClipboard(ipPublic)}
                        >
                            {isCopiedPublic ? (
                                <>
                                    <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                    <span className="ml-4 text-green-500">Скопировано!</span>
                                </>
                            ) : (
                                <>
                                    <ClipboardDocumentIcon className="w-6 h-6" />
                                    <span className="ml-4">Скопировать</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>

                {/* Изображение карты для публичного сервера */}
                <div className="lg:max-w-xs lg:w-full md:w-full w-5/6">
                    <img
                        className="object-cover object-center rounded"
                        src={getMapImagePath(findMapByCode(publicServerData.map))}
                        alt={findMapByCode(publicServerData.map)?.name ?? ''}
                    />
                </div>
            </div>

            {/* Блок с модифицированным сервером */}
            <div className="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center md:w-full">
                    <p className="font-semibold  mt-2 text-white w-full">Название: <span className="font-light">{moddedServerData.name} </span> </p>
                    <p className="font-semibold  mt-2 text-white w-full">Карта: <span className="font-light">{findMapByCode(moddedServerData.map)?.name ?? moddedServerData.map}</span></p>
                    <p className="font-semibold   mt-2 text-white w-full mb-8">Режим: <span className="font-light">{findMapByCode(moddedServerData.map)?.mode ?? ''}</span></p>

                    {/* Кнопка копирования IP */}
                    <div className="flex flex-col sm:flex-row items-center mb-4">
                        <input
                            type="text"
                            readOnly
                            className="border rounded-lg p-2 bg-white text-gray-700 w-full sm:w-auto text-center mb-2 sm:mb-0"
                            value={ipModded}
                        />
                        <button
                            className="bg-gray-100 inline-flex py-2 px-5 rounded-lg items-center hover:bg-gray-200 focus:outline-none sm:ml-2"
                            onClick={() => handleCopyToClipboardModded(ipModded)}
                        >
                            {isCopiedModded ? (
                                <>
                                    <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                    <span className="ml-4 text-green-500">Скопировано!</span>
                                </>
                            ) : (
                                <>
                                    <ClipboardDocumentIcon className="w-6 h-6" />
                                    <span className="ml-4">Скопировать</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>

                {/* Изображение карты для модифицированного сервера */}
                <div className="lg:max-w-xs lg:w-full md:w-full w-5/6">
                    <img
                        className="object-cover object-center rounded"
                        src={getMapImagePath(findMapByCode(moddedServerData.map))}
                        alt={findMapByCode(moddedServerData.map)?.name ?? ''}
                    />
                </div>
            </div>
        </section>
    );
};

export default Example;
